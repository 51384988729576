<template>
	<div class="app_dwoload">
        <vheader :navId=navId
        ></vheader>
		<!-- banner组件 -->
		<banner 
		:navName="navName"
		:navUrl="navUrl"
		></banner>
		<div class="app_banner">
			<el-tabs v-model="activeName" type="border-card">
			    <el-tab-pane  name="first">
					<span slot="label"><i></i>Android</span>
						<div class="app_load">
						<div class="app_left">
							<img src="../../assets/img/all/likefont_android_nova.png" >
							<div class="leftkhd">
								<img src="../../assets/img/all/app_dowload.png" alt="">
								<span>手机二维码扫描或微信扫一扫下载客户端</span>
							</div>
						</div>
						<div class="app_right">
							<div class="app_toptext">
								<h1>恒安集团Android客户端</h1>
								<span>开箱即用的一站式APP</span>
								<p>提供Android、iOS、Windows、macOS多种客户端形式</p>
							</div>
							<div class="middle_load">
								<button><em></em>免费下载</button>
								<button>了解详情</button>
							</div>
							<div class="fot_text">
								<p>版本：1.0.1</p>
								<p>大小：4.5MB</p>
								<p>语言：简体中文</p>
								<p>系统：Android4.6+</p>
								<p>更新：2022-05-06</p>
							</div>
						</div>
						</div>	
				</el-tab-pane>
				<el-tab-pane  name="second">
					<span slot="label"><i></i>iOS</span>
					<div class="app_load">		
					<div class="app_left">
						<img src="../../assets/img/all/likefont_ios_iphone.png" >
						<div class="leftkhd">
							<img src="../../assets/img/all/app_dowload.png" alt="">
							<span>手机二维码扫描或微信扫一扫下载客户端</span>
						</div>
					</div>
					<div class="app_right">
						<div class="app_toptext">
							<h1>恒安集团iOS客户端</h1>
							<span>开箱即用的一站式APP</span>
							<p>提供Android、iOS、Windows、macOS多种客户端形式</p>
						</div>
						<div class="middle_load">
							<button><em></em>免费下载</button>
							<button>了解详情</button>
						</div>
						<div class="fot_text">
							<p>版本：1.0.1</p>
							<p>大小：7.88MB</p>
							<p>语言：简体中文</p>
							<p>系统：iOS8.6+</p>
							<p>更新：2022-05-06</p>
						</div>
					</div>
					</div>
				</el-tab-pane>
				<el-tab-pane  name="third">
					<span slot="label"><i></i>Windows</span>
					<div class="app_load">	
					<div class="app_left">
						<img src="../../assets/img/all/likefont_windows_matebook.png" >
					</div>
					<div class="app_right">
						<div class="app_toptext">
							<h1>恒安集团Windows客户端</h1>
							<span>开箱即用的一站式APP</span>
							<p>提供Android、iOS、Windows、macOS多种客户端形式</p>
						</div>
						<div class="middle_load">
							<button><em></em>免费下载</button>
							<button>了解详情</button>
						</div>
						<div class="fot_text">
							<p>版本：2.0.0</p>
							<p>大小：68.5MB</p>
							<p>语言：简体中文</p>
							<p>系统：Windows 10/8.1/8/7</p>
							<p>更新：2022-05-10</p>
						</div>
					</div>
					</div>
				</el-tab-pane>
				<el-tab-pane  name="fourth">
					<span slot="label"><i></i> macOS</span>
					<div class="app_load">	
					<div class="app_left">
						<img src="../../assets/img/all/likefont_macos_macbook.png" >
					</div>
					<div class="app_right">
						<div class="app_toptext">
							<h1>恒安集团macOS客户端</h1>
							<span>开箱即用的一站式APP</span>
							<p>提供Android、iOS、Windows、macOS多种客户端形式</p>
						</div>
						<div class="middle_load">
							<button><em></em>免费下载</button>
							<button>了解详情</button>
						</div>
						<div class="fot_text">
							<p>版本：2.0.0</p>
							<p>大小：76.1MB</p>
							<p>语言：简体中文</p>
							<p>系统：macOS 10.11+</p>
							<p>更新：2022-05-10</p>
						</div>
					</div>
					</div>
				</el-tab-pane>
			  </el-tabs>
		</div>
		<div class="scan-code">
			<div class="client">
			<div class="client-box">
					<div class="content">
						<div class="title">手机二维码扫描或微信扫一扫访问触屏版</div>
						<div class="input-link">
							<input class="form-control" readonly="readonly" value="https://www.hengankangtai.com/#/app">
							<button class="btn-primary" >浏览</button>
						</div>
					</div>
					<div class="content content-r">
						<img src="../../assets/img/all/app_dowload.png" >
					</div>
			</div>
			</div>
		</div>
        <vfooter></vfooter>
        <GoTop/>
    </div>
</template>

<script>
import banner from '@/components/banner.vue'
import vheader from '@/components/vheader.vue'
import vfooter from '@/components/vfooter.vue'
import GoTop from '@/components/GoTop.vue'
export default { 
	name: 'app',
	data () {
		return{
			navName:'APP下载',
			navUrl:'app', 
			navId:6,
			 activeName: 'first'
        }
	},
	props: {
	},
	methods:{		
	},
	components:{
		banner,
		vheader,
		vfooter,
        GoTop
	},
}
</script>

<style  lang="less">
@media screen and (min-width:750px){
	
.app_dwoload{
	width: 100%;
	.app_banner{
		width: 100%;
		border-top: 1Px solid #DCDFE6;
		.el-tabs{
			box-shadow: none !important;
			border: 0 !important;
			.el-tabs__header{
				background-color: #fff !important;
				.el-tabs__nav-wrap{
					width: 1200Px;
					margin: 0 auto;
					.el-tabs__nav{width:100%;display: flex;justify-content: space-between;}
					.el-tabs__item{
						width:25%;
						height: 85Px;
						line-height: normal;
						&:nth-child(1){
							i{
								background: url(../../assets/img/all/android.png) no-repeat;
								&:hover{
									background: url(../../assets/img/all/androidact.png) no-repeat;
								}
							}
						}
						&:nth-child(2){
							i{
								background: url(../../assets/img/all/ios.png) no-repeat;
								&:hover{
									background: url(../../assets/img/all/iosact.png) no-repeat;
								}
							}
						}
						&:nth-child(3){
							i{
								background: url(../../assets/img/all/windows.png) no-repeat;
								&:hover{
									background: url(../../assets/img/all/windowsact.png) no-repeat;
								}
							}
						}
						&:nth-child(4){
							i{
								background: url(../../assets/img/all/ios.png) no-repeat;
								&:hover{
									background: url(../../assets/img/all/iosact.png) no-repeat;
								}
							}
						}
						span{
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							margin-top: 20px;
							i{
								width: 28Px;
								height: 29Px;
								background-size: cover !important;
							}
						}
					}
					.is-active{
						color: #093C6C !important;
						&:nth-child(1){
							i{
								
									background: url(../../assets/img/all/androidact.png) no-repeat;
								
							}
						}
						&:nth-child(2){
							i{
								
									background: url(../../assets/img/all/iosact.png) no-repeat;
								
							}
						}
						&:nth-child(3){
							i{
								
									background: url(../../assets/img/all/windowsact.png) no-repeat;
								
							}
						}
						&:nth-child(4){
							i{
								
									background: url(../../assets/img/all/iosact.png) no-repeat;
								
							}
						}
					}
				}
			}
			.el-tabs__content{
				background-color: #f8f8f8;
				.app_load{
					width: 1200px;
					display: flex;
					justify-content: space-between;
					margin: 68Px auto;
					line-height: 1.5;
					.app_left{
						width: 550Px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						img{
							
						}
						.leftkhd{
							width: 220Px;
							text-align: center;
							margin: 0 20Px;
							img{
								width: 150Px;
								height: 150Px;
								margin: 0 0 15Px;
							}
							span{
								display: block;
								font-size: 14Px;
							}
						}
					}
					.app_right{
						width: 550Px;
						padding-top: 20Px;
						.app_toptext{
							h1{
								font-size: 32Px;
								font-weight: 500;
							}
							span{
								font-size: 20Px;
								margin-top: 10Px;
							}
							p{
								font-size: 14Px;
								margin-top: 6Px;
							}
						}
						.middle_load{
							button{
								    width: 160Px;
								    line-height: 50Px;
								    overflow: hidden;
								    white-space: nowrap;
								    font-size: 18Px;
								    margin: 20Px 20Px 0 0;
								    padding: 0 5Px;
									&:hover{
										opacity: .9;
										cursor: pointer;
									}
									&:nth-child(1){
										color: #fff;
										background-color: #093C6C;
										border:0;
									}
									&:nth-child(2){
										color: #000;
										border: 1Px solid #000;
									}
								em{
									
								}
							}
						}
						.fot_text{
							margin-top: 20Px;
							p{
								font-size: 14Px;
								line-height: 1.5;
								margin-bottom: 10Px;
							}
						}
					}
				}
			}
		}
	}
	.scan-code{
		background-color: #fff;
		padding: 60Px 0;
		.client{
			width: 1200Px;
			margin: 0 auto;
		}
		.client-box{
			width: 550Px;
			display: flex;
			align-items: center;
			.content{
				
				.title{
					font-size: 20Px;
				}
				.input-link{
					margin-top: 10Px;
					.form-control{
						width: 250Px;
						    height: 30Px;
						    font-size: 12Px;
							background-color: #f2f2f2;
							border: 1Px solid #ccc;
							padding: 0 10Px;
					}
					.btn-primary{
						border: 0;
						font-size: 12Px;
						    line-height: 1.5;
						    height: 30Px;
						    width: 80Px;
						    padding: 0;
						    margin: 0 0 0 8Px;
							display: inline-block;
							color: #fff;
							background-color: #093C6C;
					}
				}
			}
			.content-r{
				img{
					width: 100Px;
					margin-left: 30Px;
				}
			}
		}
	}
}
}
@media screen and (max-width:750px){
	.app_dwoload{
		.app_banner{
			.el-tabs{
				.el-tabs__header{
					.el-tabs__nav-wrap{
						width: 95vw;
						margin: 0 auto;
						.el-tabs__item{
							height: 65Px;
							line-height: normal;
							&:nth-child(1){
								i{
									background: url(../../assets/img/all/android.png) no-repeat;
									&:hover{
										background: url(../../assets/img/all/androidact.png) no-repeat;
									}
								}
							}
							&:nth-child(2){
								i{
									background: url(../../assets/img/all/ios.png) no-repeat;
									&:hover{
										background: url(../../assets/img/all/iosact.png) no-repeat;
									}
								}
							}
							&:nth-child(3){
								i{
									background: url(../../assets/img/all/windows.png) no-repeat;
									&:hover{
										background: url(../../assets/img/all/windowsact.png) no-repeat;
									}
								}
							}
							&:nth-child(4){
								i{
									background: url(../../assets/img/all/ios.png) no-repeat;
									&:hover{
										background: url(../../assets/img/all/iosact.png) no-repeat;
									}
								}
							}
							span{
								display: flex;
								flex-direction: column;
								align-items: center;
								justify-content: center;
								margin-top: 10px;
								i{
									width: 28Px;
									height: 29Px;
									background-size: cover !important;
								}
							}
						}
						.is-active{
							color: #093C6C !important;
							&:nth-child(1){
								i{
										background: url(../../assets/img/all/androidact.png) no-repeat;
								}
							}
							&:nth-child(2){
								i{
										background: url(../../assets/img/all/iosact.png) no-repeat;
								}
							}
							&:nth-child(3){
								i{
										background: url(../../assets/img/all/windowsact.png) no-repeat;
								}
							}
							&:nth-child(4){
								i{
										background: url(../../assets/img/all/iosact.png) no-repeat;
								}
							}
						}
					}
					.el-tabs__nav-prev{
						line-height: 65Px;
					}
				}
				.el-tabs__content{
					background-color: #f8f8f8;
					.app_load{
						width: 95vw;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						margin: 38Px auto;
						line-height: 1.5;
						.app_left{
							width: 100%;
							text-align: center;
							img{
								width: 100%;
							}
							.leftkhd{
								display: none;
							}
						}
						.app_right{
							width: 95vw;
							padding-top: 20Px;
							.app_toptext{
								h1{
									font-size: 32Px;
									font-weight: 500;
								}
								span{
									font-size: 20Px;
									margin-top: 10Px;
								}
								p{
									font-size: 14Px;
									margin-top: 6Px;
								}
							}
							.middle_load{
								button{
									    width: 160Px;
									    line-height: 50Px;
									    overflow: hidden;
									    white-space: nowrap;
									    font-size: 18Px;
									    margin: 20Px 20Px 0 0;
									    padding: 0 5Px;
										&:hover{
											opacity: .9;
											cursor: pointer;
										}
										&:nth-child(1){
											color: #fff;
											background-color: #093C6C;
											border:0;
										}
										&:nth-child(2){
											color: #000;
											border: 1Px solid #000;
										}
									em{
										
									}
								}
							}
							.fot_text{
								margin-top: 20Px;
								p{
									font-size: 14Px;
									line-height: 1.5;
									margin-bottom: 8Px;
								}
							}
						}
					}
				}
			}
		}
		.scan-code{
			display: none;
		}
	}
}
</style>
